import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useParams } from "react-router-dom";
// import PaymentDetails from "./PaymentDetails";
import SuccessScreen from "../Success/index";
import { useNavigate } from "react-router-dom";

const month_list = [
  { value: "", label: "Month" },
  { value: "01", label: "Jan" },
  { value: "02", label: "Feb" },
  { value: "03", label: "Mar" },
  { value: "04", label: "Apr" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "Aug" },
  { value: "09", label: "Sept" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];
function getTenYearsList(current_year) {
  let result = [{ label: "Year", value: "" }];
  for (let i = 0; i < 10; i++) {
    result.push({ value: current_year + i, label: current_year + i });
  }
  return result;
}
function PaymentForm() {
  const [paymentData, setpaymentData] = useState({});
  const { token } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    exp_year: "",
    exp_month: "",
    card_number: "",
    cvv: "",
  });
  const [selectedValues, setSelectedValues] = useState({
    month: {},
    year: {},
  });
  const [isSubmited, setIsSubmited] = useState(false);
  const currentDate = new Date();
  const ten_years_list = getTenYearsList(currentDate.getFullYear());

  function handleSelectChange(val, type) {
    if (type === "year") {
      selectedValues.year = val;
      formData.exp_year = val.value;
    } else if (type === "month") {
      selectedValues.month = val;
      formData.exp_month = val.value;
    }
    setSelectedValues({ ...selectedValues });
    setFormData(formData);
  }
  const navigate = useNavigate();
  function handleChange(e) {
    const { value, name } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
  }

  function validateFormData(isSubmited) {
    let obj = { message: "", isValid: true };
    let isValid = true;
    let validationData = {
      name: obj,
      card_number: obj,
      month: obj,
      year: obj,
      cvv: obj,
    };
    if (isSubmited) {
      if (!formData.name) {
        validationData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      }
      if (!formData.card_number) {
        validationData.card_number = {
          message: "Card is required",
          isValid: false,
        };
        isValid = false;
      }
      if (!formData.exp_month) {
        validationData.month = {
          message: "Month is required",
          isValid: false,
        };
        isValid = false;
      }
      if (!formData.exp_year) {
        validationData.year = {
          message: "Year is required",
          isValid: false,
        };
        isValid = false;
      }
      if (!formData.cvv) {
        validationData.cvv = {
          message: "CVV is required",
          isValid: false,
        };
        isValid = false;
      }
    }
    validationData.isValid = isValid;
    return validationData;
  }
  function handleSubmit() {
    const validate = validateFormData(true);
    setIsSubmited(true);
    if (validate.isValid) {
      GetCardToken(formData);
    }
  }

  let makeChargeWithToken = (token_for_charge) => {
    const headers = {
      "Content-Type": "application/json",
      charge: `${token_for_charge}`,
    };
    let url = `https://lh.usertesting123.co.uk/subscription-plans/purchased_with_id/${token}`;

    axios
      .get(url, { headers })
      .then((response) => {
        if (response.status === 201) {
          setpaymentData(response.data.data.data);
          // localStorage.setItem(
          //   "paymentData",
          //   JSON.stringify(response.data.data.data)
          // );
          navigate(`/payment-success`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function GetCardToken(userData = {}) {
    let url =
      "https://lh.usertesting123.co.uk/subscription-plans/card_token";
    axios
      .post(url, userData)
      .then((response) => {
        if (response.data.data.token.id) {
          makeChargeWithToken(response.data.data.token.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let validateForm = isSubmited ? validateFormData(isSubmited) : {};
  return (
    <div className="wrapper">
      {paymentData?.status === "succeeded" ? (
        <SuccessScreen data={paymentData} />
      ) : (
        <form>
          <div className="col">
            <div className="row">
              <label>Name</label>
              <br />
              <input
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleChange}
              />
              {validateForm?.name?.message && (
                <span>{validateForm.name.message}</span>
              )}
            </div>
            <div>
              <label>Card Number</label>
              <input
                type="text"
                maxLength={19}
                name="card_number"
                placeholder="1234-1234-1234-1234"
                onChange={handleChange}
              />
              {validateForm?.card_number?.message && (
                <span>{validateForm.card_number.message}</span>
              )}
            </div>
            <div className="year-month">
              <label>Month</label>
              <Select
                value={selectedValues?.month}
                onChange={(option) => handleSelectChange(option, "month")}
                options={month_list}
              />
              <br />
              {validateForm?.month?.message && (
                <span>{validateForm.month.message}</span>
              )}
              <label>Year</label>
              <Select
                value={selectedValues?.year}
                onChange={(option) => handleSelectChange(option, "year")}
                options={ten_years_list}
              />
              {validateForm?.year?.message && (
                <span>{validateForm.year.message}</span>
              )}
              <br />
              {validateForm?.cvv?.message && (
                <span>{validateForm.cvv.message}</span>
              )}
              <label>CVV</label>
              <br />
              <input
                className="dont"
                type="text"
                placeholder="123"
                name="cvv"
                maxLength={3}
                onChange={handleChange}
              />
            </div>
          </div>
          <input
            type="button"
            className="btn-submit"
            value="Submit"
            onClick={handleSubmit}
          />
        </form>
      )}
    </div>
  );
}

export default PaymentForm;
