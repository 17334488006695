//import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo.png";
function Header() {
  return (
    <div className="header">
      <div className="container">
        <div className="d-flex align-items-center justify-content-center">
        <div className="logo bd">
              <a href="#."><img src={Logo} alt="" width={280} height={52}/></a>
              {/* <Link to="/"></Link> */}
            </div>
            <div className="header-button ms-auto">
            <a href="#." className="btn btn-primary app-btn" type="button">Download App</a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
