import React from "react";
import LaunchingIcon1 from "../../assets/img/launching-icon1.svg";
import LaunchingIcon2 from "../../assets/img/launching-icon2.svg";
import LaunchingIcon3 from "../../assets/img/launching-icon3.svg";
import LaunchingIcon4 from "../../assets/img/launching-icon4.svg";
import LaunchingIcon5 from "../../assets/img/launching-icon5.svg";
import LaunchingIcon6 from "../../assets/img/launching-icon6.svg";
import ConsecteturLeft from "../../assets/img/consectetur-img.png";
import Mock from "../../assets/img/mock.png";
import Accordion from "react-bootstrap/Accordion";
// import BannerVid from "../../assets/vid/banner-vid.mp4";

function Home() {
  return (
    <div className="main">
      <div className="vid-banner-sec">
        {/* <video
          width="auto"
          height="300"
          autoplay
          muted
          loop
          className="banner-video"
          playsInline>
          <source src={BannerVid} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}

        <div
          dangerouslySetInnerHTML={{
            __html: `
            <video playsinline loop autoplay muted autobuffer class="banner-video">
            <source src="${require("../../assets/vid/banner-vid.mp4")}" type="video/mp4" />
            Your browser does not support the video tag. I suggest you upgrade your browser.
        </video>            
        `,
          }}
        ></div>

        <div className="banner-content">
          <div className="banner-inner-content">
            <h2>Welcome to LoveHappiness</h2>
            <p>Where Love Meets Happiness</p>
          </div>
        </div>
      </div>

      <div className="launching-soon-section">
        <div className="container">
          <div className="launching-heading text-center">
            <h3 className="m-0">
              The dating app you have always dreamt of is launching soon.
            </h3>
          </div>
          <div className="launching-boxs">
            <div className="row">
              <div className="col-md-4 col-6 mb-md-4">
                <div className="launching-box text-center">
                  <div className="image">
                    <img src={LaunchingIcon1} alt="" />
                  </div>
                  <div className="launching-content">
                    <p className="frist-name">Fully Verified Users</p>
                    <span className="discription">
                      No more wasting your precious time
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 mb-md-4">
                <div className="launching-box text-center">
                  <div className="image">
                    <img src={LaunchingIcon2} alt="" />
                  </div>
                  <div className="launching-content">
                    <p className="frist-name last-name">Be You, Love You</p>
                    <span className="discription">
                      Learn more about yourself while searching for love
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 mb-md-4">
                <div className="launching-box text-center">
                  <div className="image">
                    <img src={LaunchingIcon3} alt="" />
                  </div>
                  <div className="launching-content">
                    <p className="frist-name">Kindness</p>
                    <span className="discription">
                      Showing kindness while interacting with our community
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="launching-box text-center">
                  <div className="image">
                    <img src={LaunchingIcon4} alt="" />
                  </div>
                  <div className="launching-content">
                    <p className="frist-name last-name">
                      Meet First, Chat Later
                    </p>
                    <span className="discription">
                      Straight to video call, no need to message for months
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="launching-box text-center">
                  <div className="image">
                    <img src={LaunchingIcon5} alt="" />
                  </div>
                  <div className="launching-content">
                    <p className="frist-name">Serious About Finding Love</p>
                    <span className="discription">
                      You're ready and prepared to put in the work to reach this
                      goal
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="launching-box text-center">
                  <div className="image">
                    <img src={LaunchingIcon6} alt="" />
                  </div>
                  <div className="launching-content">
                    <p className="frist-name last-name">Honesty</p>
                    <span className="discription">
                      Truly letting someone in to the real you, to love you for
                      who you are
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="revolution-buttons text-center mt-md-4 pt-md-5">
              <div className="container">
                <button
                  type="button"
                  className="btn btn-primary lovehappiness-btn"
                >
                  LoveHappiness Revolution Has Begun.
                </button>
                <button type="button" className="btn btn-primary find-btn">
                  Find Love
                </button>
                <button
                  type="button"
                  className="btn btn-primary download-app-btn"
                >
                  Download App
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="consectetur-section">
        <div className="container-fluid">
          <div className="row justify-content-center my-row">
            <div className="col-md-6 p-0">
              <div className="consectetur-left text-end">
                <img src={ConsecteturLeft} alt="" />
                <div className="consectetur-left-content">
                  <div className="consectetur-left-inner">
                    <p>Your love story starts right here.</p>
                    <div className="app-btn">
                      <button
                        type="button"
                        className="btn btn-primary find-btn"
                      >
                        Download App
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="consectetur-right">
                <img src={Mock} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-accodion">
        <div className="faq-accodion-inner ">
          <div className="container">
            <div className="heading">
              <h4>FAQ's</h4>
            </div>
            <Accordion defaultActiveKey="0" className="accordion-main-section">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Why LoveHappiness?</Accordion.Header>
                <Accordion.Body>
                  This is the only app that cares about your emotional wellbeing
                  as you search for a life partner.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How does LoveHappiness support my emotional wellbeing?
                </Accordion.Header>
                <Accordion.Body>
                  The app aids users with prompts to help them communicate, and
                  with fast and easy wellness checks LoveHappiness can see how
                  you are progressing and offer tailored guides to enhance your
                  dating experience. (guides are videos on how to date, and
                  wellness tests scores will show us areas of concern for each
                  user).
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Is verification mandatory?</Accordion.Header>
                <Accordion.Body>
                  No it is not. We only insist that you use your own payment
                  information for your subscription fees.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How can you ensure the person I’m talking to is who they say
                  they are? (tackling ghosting and catfishing by having real
                  interactions).
                </Accordion.Header>
                <Accordion.Body>
                  LoveHappiness is committed to tackling catfishing and
                  ghosting, and we are confident that our video features deter
                  the insincere. We believe that with video calling users can
                  meet first and chat later in confidence.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="accordion-btn text-center pt-5">
              <button type="button" className="btn btn-primary find-btn">
                I’m ready
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
