import React, { useEffect, useState } from "react";
import BannerImageSuccess from "../../assets/img/banner-image-success.png";
import Sucessbg from "../../assets/img/sucess-bg.svg";
import heartsuccess from "../../assets/img/heart-success.svg"
import rightsuccess from "../../assets/img/right-success.svg";
import logo from "../../assets/img/logo.png";

const SuccessScreen = () => {
  return <React.Fragment>
    <div className="success-main">
      <div className="success-banner">
        <img src={BannerImageSuccess} alt="" />
        <img src={Sucessbg} alt="" className="box-shadow" />
        <div className="container">
          <div className="success-img">
            <img src={heartsuccess} alt="" />
            <img src={rightsuccess} alt="" className="right-success" />
            <div className="success-text">
              <h1>Good luck meeting your match!</h1>
              <p>Someone perfect is expecting you, see you on the other side.</p>
              <div class="accordion-btn text-center pt-5">
                <button type="button" class="btn btn-primary find-btn">Back to<img src={logo} alt="" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>;
};

export default SuccessScreen;
