import { Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Home from "./pages/Home";
import PaymentForm from "./pages/Forms/PaymentForm";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import SuccessScreen from "./pages/Success";

function App() {
  return (
    <div className="App">
      <FontAwesomeIcon icon={["fab", "apple"]} />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<h1>404 Page Not Found</h1>} />
        <Route path="/payment/:token" element={<PaymentForm />} />
        <Route path="/payment-success" element={<SuccessScreen />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
