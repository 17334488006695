import Logo from "../../assets/img/logo.png";
import FacebookIcon from "../../assets/img/facebook-icon.png";
import Insta from "../../assets/img/insta.svg";
import Twiter from "../../assets/img/twiter.svg";
import Youtube from "../../assets/img/youtube.svg";
import Heart from "../../assets/img/heart.svg";
function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-logo text-center">
          <a href="#."><img src={Logo} alt="" width={280} height={52} /></a>
        </div>
        <div className="footer-social-icon text-center">
          <ul>
            <li><a href="#."><img src={FacebookIcon} alt="" /> </a></li>
            <li><a href="#."><img src={Insta} alt="" /> </a></li>
            <li><a href="#."><img src={Twiter} alt="" /> </a></li>
            <li><a href="#."><img src={Youtube} alt="" /> </a></li>
          </ul>
        </div>
        <div className="copyright-text text-center"><p>Terms &#38; Privacy</p></div>
        <div className="made-with-text text-center"><p>
          made with
          <em><img src={Heart} alt="heart icon" /></em>
          by{" "}
          <a href="https://justloyalty.co.uk/" rel="noreferrer" target="_blank">justloyalty</a>
        </p></div>
      </div>
    </div>
  );
}

export default Footer;
